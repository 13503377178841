.header,
.header__spacer,
.hero-video,
.site-partners,
.site-footer,
.media-gallery {
    display: none;
    height: 0;
    width: 0;
}

.invoice {
    * {
        color: black;
        font-family: "Arial Unicode MS", Helvetica, sans-serif;
        font-size: 10pt;
        line-height: 15px;
        margin: 0;
        padding: 0;
    }

    h2 {
        color: #808080;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 28pt;
        font-weight: bolder;
        line-height: 30px;
        margin-bottom: 10px;
        text-align: right;
    }

    h3 {
        font-size: 17pt;
        font-weight: bolder;
        line-height: 22px;
    }

    h4 {
        font-size: 15pt;
        font-weight: bolder;
        line-height: 22px;
    }

    h5 {
        font-weight: bolder;

        br {
            // This removes a 1 px dot that appears on invoices
            visibility: hidden;
        }
    }

    td {
        vertical-align: middle;
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    .center {
        text-align: center;
    }

    .left {
        margin-left: 15px;
        text-align: left;
    }

    .right {
        margin: auto 15px 0 auto;
        text-align: right;
    }

    .border-top {
        border-top: thin solid black;
    }

    .border-right {
        border-right: thin solid black;
    }

    .border-bottom {
        border-bottom: thin solid black;
    }

    .border-left {
        border-left: thin solid black;
    }

    .border-all {
        border: thin solid black;
    }

    .additional-info {
        vertical-align: top;
    }

    .hide {
        display: none;
    }
}

.graphs {
    width: 10in;

    table {
        margin: 0 auto;
    }

    .center {
        text-align: center;
    }

    .piechart, .download_piechart {
        height: 200px;
        width: 200px;
    }

    .color-key {
        width: 5%;
    }

    .name-key {
        width: 65%;
    }

    .number-key {
        width: 15%;
    }

    .number-key {
        width: 15%;
    }
}
